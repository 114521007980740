export default function tableOfContentsSidebar(el, isPageSidebar) {
  if (typeof window !== "undefined" && window.document) {
    if (typeof el !== "undefined" && el !== null) {
      // init

      const rect = el.getBoundingClientRect(), // this is the element's position
        vWidth =
          window.innerWidth || window.document.documentElement.clientWidth, // viewport
        vHeight =
          window.innerHeight || window.document.documentElement.clientHeight, // viewport
        efp = function (x, y) {
          return window.document.elementFromPoint(x, y)
        } // element from point func

      // Return false if it's not in the viewport
      if (
        rect.right < 0 ||
        rect.bottom < 0 ||
        rect.left > vWidth ||
        rect.top > vHeight
      )
        return false

      // custom
      const sidebarClass = isPageSidebar
        ? ".Page-sidebar-toc"
        : ".Blog-sidebar-toc"
      const sidebar = window.document.querySelector(sidebarClass)
      const toc = window.document.querySelector(".toc")
      const tocWidth = toc.offsetWidth
      const sidebarWidth = tocWidth - 50

      if (!sidebar) return

      if (vWidth < 841) {
        sidebar.classList.remove("sticky")
        sidebar.classList.remove("abs")
        sidebar.classList.remove("fix")
        sidebar.classList.add("rel")
      } else {
        if (toc.offsetHeight >= vHeight) {
          sidebar.setAttribute(
            "style",
            `max-height: ${parseInt(vHeight) - 200}`
          )
        }
        // going past the top ie - scrolling down
        if (rect.top < 0) {
          const headerHeight = parseInt(
            window.document.querySelector(".Header").getBoundingClientRect()
              .height + 50
          )
          const sidebarHeight = parseInt(sidebar.getBoundingClientRect().height)
          const mainContentHeight = parseInt(
            window.document
              .querySelector(".toc-main-content-wrapper")
              .getBoundingClientRect().height
          )
          const scrollArea = -rect.top + headerHeight + sidebarHeight

          sidebar.classList.add("sticky")

          sidebar.style.top = headerHeight

          if (scrollArea >= mainContentHeight) {
            sidebar.classList.remove("fix")
            sidebar.classList.remove("rel")

            if (toc.offsetHeight >= vHeight) {
              sidebar.setAttribute(
                "style",
                `top: ${mainContentHeight - sidebarHeight}px;
                 max-height: ${parseInt(vHeight) - 200}px;
                 width: ${sidebarWidth}px;`
              )
            } else {
              sidebar.setAttribute(
                "style",
                `top: ${mainContentHeight - sidebarHeight}px`
              )
            }
            return false
          } else {
            sidebar.classList.remove("abs")
            sidebar.classList.remove("rel")
            sidebar.classList.add("fix")

            if (toc.offsetHeight >= vHeight) {
              sidebar.setAttribute(
                "style",
                `top: 160px;
                 width: ${sidebarWidth}px;
                 max-height: ${parseInt(vHeight) - 200}px`
              )
            } else {
              sidebar.setAttribute(
                "style",
                `top: 160px; width: ${sidebarWidth}px`
              )
            }
          }
        } else {
          // back at the top
          sidebar.style.top = 0
          sidebar.classList.remove("sticky")
          sidebar.classList.remove("abs")
          sidebar.classList.remove("fix")
          sidebar.classList.add("rel")
          if (toc.offsetHeight >= vHeight) {
            sidebar.setAttribute(
              "style",
              `width: ${sidebarWidth}px;
               max-height: ${parseInt(vHeight) - 200}px`
            )
          } else {
            sidebar.setAttribute("style", `width: ${sidebarWidth}px`)
          }
        }
      }
    }
  }
}
