/**
 * Show more block, Pricing Tier accordion on mobile, Drug Table accordion on mobile
 */
export default function showMore() {
  if (typeof window !== "undefined" && window.document) {
    const showMoreToggles = document.querySelectorAll(
      ".ShowMoreBlock-toggle, .ShowMoreBlock-above"
    )
    // nearest PricingTierWithAccordionBlock goes to is-toggled
    const pricingTierToggles = document.getElementsByClassName(
      "pricing-tier-toggle-button"
    )
    // DrugScreenTableBlock-drug-screen goes to is-open
    const drugTableToggles = document.getElementsByClassName(
      "DrugScreenTableBlock-drug-screen-title"
    )

    for (let toggle of Array.from(showMoreToggles)) {
      toggle.addEventListener("click", function (e) {
        e.preventDefault()
        this.closest(".ShowMoreBlock").classList.toggle("is-toggled")
      })
    }

    for (let toggle of Array.from(pricingTierToggles)) {
      toggle.addEventListener("click", function (e) {
        e.preventDefault()
        this.closest(".PricingTierWithAccordionBlock").classList.toggle(
          "is-toggled"
        )
      })
    }

    for (let toggle of Array.from(drugTableToggles)) {
      toggle.addEventListener("click", function (e) {
        e.preventDefault()
        this.closest(".DrugScreenTableBlock-drug-screen").classList.toggle(
          "is-open"
        )
      })
    }
  }
}
