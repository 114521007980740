export default function stateLawsArchive() {
  if (typeof window !== "undefined" && window.document) {
    const map = document.getElementById("united-states-map")
    if (!map) return

    const stateList = document.querySelectorAll(".StateLawsDiagram-list li")
    const dialog = document.querySelector(".StateLawsDiagram-dialog")
    const dialogTitle = dialog.querySelector("span")
    const dialogLaws = dialog.querySelector("ul")
    const dropdown = document?.querySelector(
      ".StateLawsDiagram-dropdown .Select"
    )
    const dropdownOptions = dropdown?.querySelector(".Select-options")
    const stateOptions = dropdownOptions?.querySelectorAll("li")

    dialog.addEventListener("mouseenter", e => e.preventDefault())

    dropdown?.addEventListener("click", e => {
      dropdown.classList.toggle("is-active")
      dropdownOptions.style.display =
        dropdownOptions.style.display == "none" ? "block" : "none"
    })

    const statesData = {}
    for (const state of stateList) {
      const data = state.dataset
      statesData[data.code] = data
      state.addEventListener("mouseenter", updateMap)
      state.addEventListener("mouseleave", updateMap)
      state.addEventListener("click", goToStateLaw)
    }

    if (stateOptions?.length) {
      for (const stateOption of stateOptions) {
        stateOption.addEventListener("mouseenter", updateMap)
        stateOption.addEventListener("mouseleave", updateMap)
        stateOption.addEventListener("click", goToStateLaw)
      }
    }

    map.addEventListener("mousemove", updateMap)
    map.addEventListener("click", goToStateLaw)

    let currentStateSVG = null

    function updateMap(e) {
      if (e.target === map) {
        currentStateSVG?.classList.remove("is-active")
        currentStateSVG = null
        dialogPosition()
        return
      }

      const code = e.target.dataset?.code || e.target.id
      if (!code) return

      const stateSVG = map.getElementById(`${code}`)
      if (!stateSVG) return

      if (e.type === "mouseleave") {
        stateSVG.classList.remove("is-active")
        dialogPosition()
        currentStateSVG = null
        return
      }

      if (e.type === "mousemove" && stateSVG === currentStateSVG) return

      currentStateSVG?.classList.remove("is-active")
      stateSVG.classList.add("is-active")
      dialogPosition(stateSVG)
      currentStateSVG = stateSVG
    }

    function goToStateLaw(e) {
      const code = e.currentTarget?.dataset?.code || e.target.id
      if (!code) return
      if (!statesData[code]) return
      const url = new URL(statesData[code].url, window.location.origin)
      window.location = url.pathname
    }

    function dialogPosition(currentStateEl = null) {
      if (currentStateEl) {
        let mapRect = { height: 583, width: 919 }
        let stateRect = currentStateEl.getBBox()

        let left = (stateRect.x + stateRect.width * 0.5) / mapRect.width
        let top =
          (stateRect.y + stateRect.height * 0.5 - mapRect.height * 0.05) /
          mapRect.height

        dialog.style.display = "block"
        dialog.style.left = `${left * 100}%`
        dialog.style.top = `${top * 100}%`

        const data = statesData[currentStateEl.id]
        dialogTitle.innerHTML = `<strong>${data.title}</strong>`

        dialogLaws.innerHTML = null
        const laws = data.laws.split(",")
        laws.forEach(law => {
          const item = document.createElement("LI")
          item.innerText = law
          dialogLaws.appendChild(item)
        })
      } else {
        dialog.style.display = "none"
      }
    }
  }
}
