/**
 * Customer Quotes block
 */
export default function customerQuotes() {
  if (typeof window !== "undefined" && window.document) {
    // From https://www.digitalocean.com/community/tutorials/js-tabs
    function pagify(element) {
      const customerQuotesBlock = element.querySelector(
        ".CustomerQuotesBlock-items"
      )
      const pagerControl = element.querySelector(".CustomerQuotesBlock-pager")
      const quotes = customerQuotesBlock.querySelectorAll(".CustomerQuoteBlock")

      if (quotes.length < 2) return

      const pages = quotes.length

      let thePage = 1

      const pageButtons = []
      for (let i = 1; i <= pages; i++) {
        let pageButton = document.createElement("DIV")
        pageButton.addEventListener("click", e => {
          page(i)
        })
        pageButtons.push(pageButton)
        pagerControl.appendChild(pageButton)
      }

      function page(pageIndex) {
        for (let index = 0; index < quotes.length; index++) {
          if (index + 1 === pageIndex) {
            quotes[index].classList.add("is-visible")
          } else {
            quotes[index].classList.remove("is-visible")
          }
        }

        pageButtons[thePage - 1].classList.remove("is-active")
        pageButtons[pageIndex - 1].classList.add("is-active")
        thePage = pageIndex
      }
      page(thePage)

      let maxHeight = 0
      quotes.forEach(x => {
        if (x.clientHeight > maxHeight) {
          maxHeight = x.clientHeight
        }
        x.style.display = ""
        x.style.height = `${maxHeight}px`
      })
    }

    const quotesBlocks = document.querySelectorAll(".CustomerQuotesBlock")
    if (quotesBlocks.length > 0) {
      quotesBlocks.forEach(x => pagify(x))
    }

    if (!window.goodhireJs) {
      window.goodhireJs = {}
    }
    window.goodhireJs.customerQuotes = true
  }
}
