/**
 * Tabs block
 */
export default function tabs() {
  if (typeof window !== "undefined" && window.document) {
    function tabify(element) {
      const header = element.querySelector(".TabsBlock-labels ul")
      const content = element.querySelector(".TabsBlock-tabs")
      const tab_headers = [...header.children]
      const tab_contents = document.querySelectorAll(".TabsBlock-tab")

      let current_tab_index = -1
      let max_height = 0

      function setTab(index) {
        if (current_tab_index > -1) {
          tab_headers[current_tab_index].classList.remove("is-active")
          tab_contents[current_tab_index].classList.remove("is-active")
        }

        tab_headers[index].classList.add("is-active")
        tab_contents[index].classList.add("is-active")

        current_tab_index = index

        // Set all tabs to be the same height
        // Check height of tabs that were hidden on page load
        tab_contents[index].style.height = ""
        tab_contents.forEach(x => {
          if (x.clientHeight > max_height) {
            max_height = x.clientHeight
          }
        })

        tab_contents.forEach(x => {
          x.style.display = ""
          if (Math.abs(max_height - x.clientHeight) > 300) {
            return
          } else {
            x.style.height = `${max_height}px`
          }
        })
      }

      let default_tab_index = tab_headers.findIndex(x => {
        return [...x.classList].indexOf("is-active") > -1
      })

      default_tab_index = default_tab_index === -1 ? 0 : default_tab_index
      setTab(default_tab_index)

      tab_headers.forEach((x, i) => (x.onclick = event => setTab(i)))
    }

    // this is where the magic happens!
    ;[...document.querySelectorAll(".TabsBlock")].forEach(x => tabify(x))
  }
}
