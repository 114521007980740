export default function resourcesLoop() {
  if (typeof window !== "undefined" && window.document) {
    //Resources loop block
    // if (!window.goodhireJs || !window.goodhireJs.resourcesLoop) {

    // From https://www.digitalocean.com/community/tutorials/js-tabs
    function pagify(element) {
      const resourcesBlock = element.querySelector(".Grid")
      const pagerControl = element.querySelector(".CustomerQuotesBlock-pager")
      const blocks = [...resourcesBlock.children]

      // console.log('blocks', blocks)
      const pages = Math.ceil(blocks.length / 3)

      let thePage = 1

      // console.log('pages', pages);
      const pageButtons = []
      if (blocks.length > 4) {
        for (let i = 1; i <= pages; i++) {
          let pageButton = document.createElement("DIV")
          pageButton.addEventListener("click", e => {
            page(i)
          })
          pageButtons.push(pageButton)
          pagerControl.appendChild(pageButton)
        }
      }

      function page(pageIndex) {
        // console.log('pageIndex', pageIndex)
        for (let index = 0; index < blocks.length; index++) {
          if (Math.ceil((index + 1) / 4) === pageIndex) {
            // console.log(Math.ceil((index + 1) / 4))
            blocks[index].classList.add("show-item")
          } else {
            blocks[index].classList.remove("show-item")
          }
          // console.log('block, index', blocks[index], index)
        }

        // console.log('pageButtons', pageButtons);
        if (pageButtons.length) {
          pageButtons[thePage - 1].classList.remove("is-active")
          pageButtons[pageIndex - 1].classList.add("is-active")
          thePage = pageIndex
        }
      }

      page(thePage)

      let maxHeight = 0
      blocks.forEach(x => {
        if (x.clientHeight > maxHeight) {
          maxHeight = x.clientHeight
        }
        x.style.display = ""
        x.style.height = `${maxHeight}px`
      })
    }

    ;[...document.querySelectorAll(".ResourcesLoopBlock")].forEach(x =>
      pagify(x)
    )

    if (!window.goodhireJs) {
      window.goodhireJs = {}
    }
    window.goodhireJs.resourcesLoop = true

    // }
  }
}
