import axios from "axios"

/** This script has been ported over from wp and converted from JQuery to vanilla js */
export default function buildJobBoard() {
  if (typeof window !== "undefined" && window.document) {
    const jobsList = document.querySelector("#jobs-container .jobs-list")
    if (!jobsList) return

    var leverParameter = ""
    var pageUrl = ""
    var trackingPrefix = ""
    var url = ""
    // Replace "leverdemo" with your own company name
    //url = 'https://api.lever.co/v0/postings/inflection?group=team&location=Kyiv&mode=json'
    url = "https://api.lever.co/v0/postings/inflection?group=team&mode=json"

    //Checking for potential Lever source or origin parameters
    pageUrl = window.location.href
    leverParameter = ""
    trackingPrefix = "?lever-"

    if (pageUrl.indexOf(trackingPrefix) >= 0) {
      // Found Lever parameter
      var pageUrlSplit = pageUrl.split(trackingPrefix)
      leverParameter = "?lever-" + pageUrlSplit[1]
    }

    //Fetching job postings from Lever's postings API
    axios.get(url).then(response => {
      createJobs(response?.data)
    })

    //Functions for checking if the variable is unspecified
    function cleanString(string) {
      if (string) {
        var cleanString = string.replace(/\s+/gi, "")
        return cleanString
      } else {
        return "Uncategorized"
      }
    }

    function nullCheck(string) {
      if (!string) {
        var result = "Uncategorized"
        return result
      } else {
        return string
      }
    }

    function createJobs(_data) {
      for (let i = 0; i < _data.length; i++) {
        for (let j = 0; j < _data[i].postings.length; j++) {
          var posting = _data[i].postings[j]
          var title = posting.text
          var description = posting.description
          //Making each job description shorter than 250 characters
          var shortDescription =
            description.trim().substring(0, 250).replace("\n", " ") + "..."
          var location = nullCheck(posting.categories.location)
          var locationCleanString = cleanString(location)
          var commitment = nullCheck(posting.categories.commitment)
          var commitmentCleanString = cleanString(commitment)
          var team = nullCheck(posting.categories.team)
          var teamCleanString = cleanString(team)
          var levelParam = leverParameter ? leverParameter : ""
          var link = posting.hostedUrl + leverParameter

          // Build outermost container
          let jobListing = document.createElement("DIV")
          jobListing.classList.add("job")
          jobListing.classList.add(teamCleanString)
          jobListing.classList.add(locationCleanString)
          jobListing.classList.add(commitmentCleanString)
          jobListing.innerHTML =
            '<div class="job-title-description">' +
            '<h3><a class="job-title" href="' +
            link +
            '" target="_blank" rel="noopener noreferrer">' +
            title +
            "</a></h3>" +
            '<div class="department">' +
            team +
            " - " +
            location +
            "</div>" +
            '<p class="description"><span>' +
            shortDescription +
            '</span> &nbsp; <a href="' +
            link +
            '" class="more" target="_blank" rel="noopener noreferrer">Read more</a></p>' +
            "</div>"

          jobsList.appendChild(jobListing)
        }
      }
    }
  }
}
