export default function packagesTabs() {
  if (typeof window !== "undefined" && window.document) {
    //Packages Tabs block
    // if (!window.goodhireJs || !window.goodhireJs.packagetabs) {

    // From https://www.digitalocean.com/community/tutorials/js-tabs
    function tabify(element) {
      const header = element.querySelector(".tabbed-block__tabs")
      const content = element.querySelector(".tabbed-block__content")
      const tab_headers = [...header.children]
      const tab_contents = [...content.children]

      let current_tab_index = -1

      function setTab(index) {
        if (current_tab_index > -1) {
          // console.log('tab index', current_tab_index)
          tab_headers[current_tab_index].classList.remove("active")
          tab_contents[current_tab_index].classList.remove("show")
          tab_contents[current_tab_index].classList.add("hide")
        }
        tab_headers[index].classList.add("active")
        tab_contents[index].classList.add("show")
        tab_contents[index].classList.remove("hide")
        current_tab_index = index
      }

      let default_tab_index = tab_headers.findIndex(x => {
        return [...x.classList].indexOf("default-tab") > -1
      })

      default_tab_index = default_tab_index === -1 ? 0 : default_tab_index
      setTab(default_tab_index)

      tab_headers.forEach((x, i) => (x.onclick = event => setTab(i)))

      let max_height = 0
      tab_contents.forEach(x => {
        if (x.clientHeight > max_height) {
          max_height = x.clientHeight
        }
        x.style.display = ""
        x.style.height = `${max_height}px`
      })
    }
    ;[...document.querySelectorAll(".TabbedBlockBlock")].forEach(x => tabify(x))

    //modal functionality for this block
    if (document.querySelectorAll(".ModalsBlock").length) {
      const modalLinks = document.querySelectorAll(".ModalsBlock[modal-title]")

      const modalsArray = [...modalLinks]

      modalsArray.forEach(modalLink => {
        modalLink.addEventListener("click", e => {
          if (modalLink.querySelector(".modals__content")) {
            modalLink.querySelector(".modals__content").classList.add("active")
            document.querySelector(".md-overlay").classList.add("active")
            document.body.style.overflow = "hidden"
            modalLink.closest(".Section-wrap").style.position = "static"
          }
        })

        if (modalLink.querySelector(".modal__close")) {
          modalLink
            .querySelector(".modal__close")
            .addEventListener("click", e => {
              e.stopPropagation()
              if (modalLink.querySelector(".modals__content")) {
                console.log("click")
                modalLink
                  .querySelector(".modals__content")
                  .classList.remove("active")
                document.querySelector(".md-overlay").classList.remove("active")
                document.body.style.overflow = "auto"
                modalLink.closest(".Section-wrap").style.position = "relative"
              }
            })

          document.querySelector(".md-overlay").addEventListener("click", e => {
            e.stopPropagation()
            if (modalLink.querySelector(".modals__content")) {
              console.log("click")
              modalLink
                .querySelector(".modals__content")
                .classList.remove("active")
              document.querySelector(".md-overlay").classList.remove("active")
              document.body.style.overflow = "auto"
              modalLink.closest(".Section-wrap").style.position = "relative"
            }
          })
        }
      })
    }

    if (!window.goodhireJs) {
      window.goodhireJs = {}
    }
    window.goodhireJs.packagetabs = true
  }
}
